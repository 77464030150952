import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
// import Contact from './Contact';

function Cards() {
  return (
    <div className='cards'>
      <h1>Services</h1>
      {/* <p>Build Your Future with us now!</p> */}
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>        
            <CardItem
              src='images/mobile1.png'
              text='Custom app development tailored
               for Android and iOS platforms,
                enhancing user experiences and
                 driving engagement.'
              label='Mobile App Dev'
              path='/services'
            />
            <CardItem
              src='images/web.png'
              text='Robust and scalable web solutions
               designed to meet diverse business needs,
                ensuring seamless user experiences and
                 high performance.'
              label='Web App Dev'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>        
            <CardItem
              src='images/energy1.png'
              text='Offering high-efficiency solar and
               wind energy products alongside a commitment
                to eco-friendly initiatives and
                 sustainability.'
              label='Energy'
              path='/services'
            />
            <CardItem
              src='images/robotics.png'
              text='Integrating cutting-edge robotics
               technology for various applications,
                automating processes, and enhancing
                 efficiency.'
              label='Robotics'
              path='/services'
            />
          </ul>
          
        </div>
      </div>
      <div className='cards__container'>
      <h1>Projects</h1>
        <div className='cards__wrapper'>
          <ul className='cards__items'>        
            <CardItem
              src='images/s1.png'
              text='Digitizing birth certification services to enhance
               accessibility, efficiency, and user satisfaction,
                while ensuring secure management of citizen data,
                 aiming for improved accountability, service delivery
                  efficiency, and citizen satisfaction.'
              label='BirthPass'
              path='/services'
            />
            <CardItem
              src='images/agroo.png'
              text='Agroo AI aims to revolutionize agriculture through
               advanced artificial intelligence, optimizing crop
                management, increasing yield efficiency, and promoting
                 sustainable farming practices.'
              label='Agroo AI'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>        
            <CardItem
              src='images/sm.jpg'
              text='A Smart meter technology solution to store excess
               electricity for citizens in the scarsity of
                electricity during the dry season'
              label='Empowered Grid'
              path='/services'
            />
            <CardItem
              src='images/deco.jpg'
              text='A solution focusing on transforming living spaces
               with stylish and functional interior design solutions,
                tailored to reflect personal tastes and enhance living comfort.'
              label='Home Deco'
              path='/services'
            />
          </ul>
          <div className='cards__container'>
      <h1>Team</h1>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/tina.jpg'
              text='Miss. Irene Tina Dennis - Software Engineer'
              label='CEO'
              path='/services'
            />
            <CardItem
              src='images/melo.jpg'
              text='Mr. Matthias B.E Luogon - Machine Learning Engineer'
              label='CTO'
              path='/products'
            />
            <CardItem
              src='images/js.jpg'
              text='Mr. Joshua B Sangar - Software Engineer'
              label='CIO'
              path='/sign-up'
            />
          </ul>
          
          <ul className='cards__items'>        
            <CardItem
              src='images/flomo.jpg'
              text='Mr. D. Lucius Flomo - Network Engineer'
              label='System Admin'
              path='/services'
            />
            <CardItem
              src='images/koko.jpg'
              text='Miss. Diana Kokulo - Software Engineer'
              label='HRTM'
              path='/services'
            />
            <CardItem
              src='images/dan.jpg'
              text=' Mr. Daniel Thompson - Software Engineer'
              label='IFM'
              path='/services'
            />
            <CardItem
              src='images/han.jpg'
              text='Miss. Henrietta Summerville - Software Engineer'
              label='MBDM'
              path='/services'
            />
          </ul>
          </div>
          </div>
          <div className='contact-form'>
        <h2>Contact Us</h2>
        <form>
          <label htmlFor='name'>Name:</label>
          <input type='text' id='name' name='name' required />
          
          <label htmlFor='email'>Email:</label>
          <input type='email' id='email' name='email' required />
          
          <label htmlFor='message'>Message:</label>
          <textarea id='message' name='message' rows='4' required></textarea>
          
          <button type='submit'>Send</button>
        </form>
      </div>
        </div>
      </div>
    </div>
    
    
  );
}

export default Cards;
