import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTreatmentsByClass } from './Therapy.js';
import './Therapydetails.css';
import CardItem from './CardItem';
import Navbar from './Navbar';
import Footer from './Footer.js';
import '../App.css';

function Therapydetails() {
  const location = useLocation();
  const myProp = location.state?.myProp;
  console.log("The received prop is: " + myProp);
  const details = getTreatmentsByClass(myProp)[0];
  console.log("The Details Fetched are: ", details);

  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    {
      src: 'images/blight.jpg',
      text: 'Spray affected plants with a fungicide to control the Alternaria Leaf Blight disease.',
      label: 'Alternaria Leaf Blight',
      path: '/services',
    },
    // Add more images here if needed
    {
      src: 'images/healthy.jpg',
      text: 'Do Nothing.',
      label: 'Healthy',
      path: '/services',
    },
    {
      src: 'images/pd.jpg',
      text: 'Spray affected plants with a bactericide to control the Potassium Deficiency disease.',
      label: 'Potassium Deficiency',
      path: '/services',
    }
  ];

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <div className='slider'>
      <Navbar />
      <h1>Unveil the Advantages of AI in Plant Care for Modern Sweet Potato Leaf Farming!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            {images.map((image, index) => (
              <CardItem
                key={index}
                src={image.src}
                text={image.text}
                label={image.label}
                path={image.path}
                isActive={index === currentIndex}
              />
            ))}
          </ul>
        </div>
      </div>
      {/* <div className='slider__controls'>
        <button className='slider__prev' onClick={handlePrevSlide}>
          Previous
        </button>
        <button className='slider__next' onClick={handleNextSlide}>
          Next
        </button>
      </div> */}

      <div style={{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        // maxHeight: "110vh",
        padding: '100px',
        textAlign: 'center',
        backgroundColor: '#f2f2f2'
      }}>
        <h2 style={{ color: 'black', fontSize: '24px', marginTop: '-100px' }}>{details.name}</h2>
        <p style={{ fontWeight: 'bold', color: 'black', fontSize: '18px', lineHeight: '2' }}>{details.treatment}</p>
        <p style={{ color: 'black', fontSize: '16px', marginTop: '30px' }}>{details.details}</p>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Therapydetails;
