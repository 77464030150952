import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
    {/* <img src='/images/rena.jpg' alt='Hero Image' className='hero-image' /> */}
    <div className='hero-btns'>
      <Button
        className='btns'
        buttonStyle='btn--outline'
        buttonSize='btn--large'
        component={Link}
        to='services'
      >
        Learn More!
      </Button>
    </div>
  </div>
  );
}

export default HeroSection;
