import React from "react";
import "./Therapy.css";

const TREATMENTS = [{
    
    "id": 1,
    "name": <h2>"Fungicide treatment"</h2>,
    "treatment": "Spray affected plants with a fungicide to control the Alternaria Leaf Blight disease.",
    "class": "Alternaria_Leaf_Blight",
    "details": <div className="pot">
    <p className="Potassium">
<ul><li>Identify the symptoms: Before proceeding with treatment, make sure you correctly identify the symptoms of Alternaria Leaf Blight. Look for small, dark brown to black spots on the leaves that gradually enlarge and develop a concentric ring pattern. The spots may also have a yellow halo surrounding them. As the disease progresses, the leaves may turn yellow, dry out, and eventually die.</li></ul>
<ul><li>Sanitation: Start by practicing good sanitation measures to prevent the spread and recurrence of the disease. Remove and destroy any infected plant material, including leaves, stems, and vines. Do not compost the infected plant debris as it can contribute to the disease's spread. Clean and disinfect gardening tools after each use to prevent cross-contamination.</li></ul>

<ul><li>Fungicide application: If the Alternaria Leaf Blight infection is severe or persistent, you may need to use a fungicide. Choose a fungicide labeled for use on sweet potatoes and specifically effective against Alternaria species. Follow the instructions on the product label regarding application rates and timings. Apply the fungicide evenly to both sides of the leaves, ensuring thorough coverage.</li></ul>

<ul><li>Crop rotation: Avoid planting sweet potatoes in the same area where Alternaria Leaf Blight has been a problem in previous seasons. Rotate the crop with unrelated plants to break the disease cycle. This practice helps reduce the buildup of pathogens in the soil and minimizes the chances of reinfection.</li></ul>

<ul><li>Resistant varieties: When selecting sweet potato varieties for planting, choose those known to have resistance or tolerance to Alternaria Leaf Blight. Consult with local agricultural extension services or seed suppliers to identify suitable resistant varieties for your region.</li></ul>

<ul><li>Proper spacing: Provide adequate spacing between sweet potato plants to promote air circulation and reduce humidity levels. Dense planting can create a favorable environment for the disease to develop and spread. Follow the recommended plant spacing guidelines for sweet potatoes.</li></ul>

<ul><li>Irrigation management: Avoid overhead irrigation or watering late in the day, as this can promote prolonged leaf wetness and create favorable conditions for disease development. Instead, use drip irrigation or water at the base of the plants early in the morning. This allows the foliage to dry quickly, reducing the risk of infection.</li></ul>

<ul><li>Mulching: Apply a layer of organic mulch around the base of sweet potato plants to prevent soil splashing onto the leaves. This can help reduce the spread of fungal spores. Use straw, wood chips, or other suitable mulching materials.</li></ul>

<ul><li>Monitoring: Regularly inspect your sweet potato plants for signs of Alternaria Leaf Blight throughout the growing season. Catching the disease early allows for prompt intervention and better control. If you notice any symptoms, take action immediately to prevent further spread.</li></ul>

<ul><li>Consult experts: If the Alternaria Leaf Blight problem persists despite your efforts, consider seeking advice from local agricultural extension services, plant pathologists, or experienced growers in your area. They can provide tailored recommendations and guidance based on specific local conditions.</li></ul>

Remember, a comprehensive approach that combines proper cultural practices, sanitation, and, if necessary, targeted fungicide applications is key to managing Alternaria Leaf Blight effectively in sweet potatoes.
</p>
    </div> 
    },
    {
    "id": 2,
    "name": <h2>"Bactericide treatment"</h2> ,
    "treatment": "Spray affected plants with a bactericide to control the Potassium Deficiency disease.",
    "class": "Potassium_Deficiency",
    "details": <div className="pot">
<p className="Potassium">
Treating potassium deficiency in sweet potato leaves involves implementing various measures to supply the plant with an adequate amount of potassium. Here is a guideline to help you treat potassium deficiency in sweet potato leaves:

<ul><li>Identify the symptoms: Before proceeding with treatment, ensure that the symptoms you observe in the sweet potato leaves are indeed caused by potassium deficiency. Symptoms of potassium deficiency include yellowing and browning of leaf edges, interveinal chlorosis (yellowing between veins), leaf curling, and stunted growth.</li></ul>

<ul><li>Soil testing: Conduct a soil test to determine the potassium levels in the soil. This will help you gauge the severity of the deficiency and adjust the treatment accordingly. Soil testing kits are available at garden centers, or you can send a sample to a laboratory for more accurate results.</li></ul>

<ul><li>Organic matter and compost: Incorporate organic matter and compost into the soil to improve its overall fertility. Well-rotted compost or aged manure can be mixed into the top few inches of soil before planting sweet potatoes or as a side dressing around existing plants. Organic matter helps retain moisture and provides a slow-release source of nutrients, including potassium.</li></ul>

<ul><li>Fertilizer application: If the soil test indicates a potassium deficiency, apply a potassium-rich fertilizer specifically formulated for vegetables or sweet potatoes. Look for a fertilizer with a higher potassium (K) content relative to nitrogen (N) and phosphorus (P). Follow the instructions on the fertilizer package for application rates and timings.</li></ul>

<ul><li>Foliar spray: In addition to soil application, you can use a foliar spray to provide a quick boost of potassium directly to the leaves. Choose a foliar fertilizer that contains potassium, and apply it according to the manufacturer's instructions. Make sure to thoroughly cover both sides of the leaves.</li></ul>

<ul><li>Balanced fertilization: Maintain a balanced fertilizer program throughout the growing season to prevent further nutrient deficiencies. Too much nitrogen can exacerbate potassium deficiency symptoms, so it's important to ensure a proper balance of all essential nutrients. Follow recommended fertilization guidelines for sweet potatoes.</li></ul>

<ul><li>Irrigation management: Adequate watering is crucial for nutrient uptake in sweet potato plants. Monitor soil moisture levels and water the plants regularly, especially during dry periods. Avoid overwatering, as it can leach nutrients from the soil.</li></ul>

<ul><li>Mulching: Apply a layer of organic mulch around the base of the sweet potato plants. Mulch helps conserve moisture, regulate soil temperature, and suppress weed growth. Organic mulches like straw, wood chips, or compost also contribute to soil fertility over time, including potassium.</li></ul>

<ul><li>Crop rotation: Rotate sweet potatoes with other crops to reduce the risk of nutrient deficiencies and soil imbalances. This practice helps break pest and disease cycles while allowing the soil to recover and replenish its nutrient content.</li></ul>

<ul><li>Monitor progress: Keep a close eye on the sweet potato plants and monitor the improvement of the leaf symptoms over time. Adjust the treatment regimen as necessary based on the plant's response and ongoing soil tests.</li></ul>

Remember, prevention is always better than treatment. Regular soil testing, balanced fertilization, and proper cultural practices can help prevent nutrient deficiencies in sweet potatoes and promote healthy growth.
</p>
</div> 
    },
   
    {
    "id": 4,
    "name": "Healthy Plants",
    "treatment": "'Do Nothing.'",
    "class": "Healthy",
    "details":  <div className="pot">
    <p className="Potassium">
    There is basically no treatment for a healthy sweet potato leaf but here's a guideline for maintaining the health of potato leaves:
    
    <ul><li>Adequate sunlight: Ensure that potato plants receive sufficient sunlight. Place them in an area where they can receive at least 6-8 hours of direct sunlight per day. Sufficient sunlight promotes photosynthesis, which is essential for healthy leaf development.</li></ul>
    
    <ul><li>Proper watering: Provide regular and consistent watering to potato plants. Keep the soil evenly moist but avoid overwatering, as it can lead to root rot and other issues. Monitor soil moisture levels and adjust watering frequency based on weather conditions. Water the plants at the base to avoid wetting the foliage, which can promote the development of diseases.</li></ul>
    
    <ul><li>Nutrient-rich soil: Prepare the soil before planting by incorporating organic matter such as compost or well-rotted manure. This helps improve soil fertility and provides essential nutrients for healthy leaf growth. Conduct a soil test to assess nutrient levels and adjust fertilizer application accordingly. Follow recommended fertilization guidelines for potatoes to ensure proper nutrient balance.</li></ul>
    
    <ul><li>Balanced fertilization: Use a balanced fertilizer that provides essential nutrients like nitrogen (N), phosphorus (P), and potassium (K). Follow the fertilizer manufacturer's instructions for application rates and timings. Providing a balanced nutrient supply helps promote overall plant health and vigorous leaf growth.</li></ul>
   
    <ul><li>Pest and disease management: Monitor potato plants regularly for any signs of pests or diseases that can damage the leaves. Common potato pests include aphids, Colorado potato beetles, and leafhoppers, while diseases such as early blight and late blight can affect the leaves. Take appropriate measures such as using organic or chemical pest controls, implementing crop rotation, and practicing good sanitation to prevent and manage pests and diseases.</li></ul>
   
    <ul><li>Proper spacing: Plant potato seedlings or tubers at the recommended spacing to ensure adequate air circulation between plants. Crowded plants can create a humid microclimate that promotes the development of fungal diseases. Follow the spacing guidelines provided for the specific potato variety you are growing.</li></ul>
   
    <ul><li>Mulching: Apply a layer of organic mulch, such as straw or wood chips, around the base of potato plants. Mulching helps conserve moisture, regulate soil temperature, suppress weed growth, and prevent soil splashing onto the leaves. It also acts as a natural source of organic matter as it breaks down over time.</li></ul>
   
    <ul><li>Timely harvesting: Harvest potatoes when they reach maturity to prevent overcrowding and competition among plants. Leaving mature potatoes in the ground for an extended period can lead to shading of lower leaves, reducing their health and vigor.</li></ul>
   
    <ul><li>Regular pruning: Remove any yellowing, damaged, or diseased leaves promptly to prevent the spread of diseases and to maintain the overall health of the plant. Use clean, sharp pruning shears and sanitize them between cuts to prevent cross-contamination.</li></ul>
    
    <ul><li>Monitoring and observation: Regularly observe the potato leaves for any signs of stress, nutrient deficiencies, or abnormalities. Promptly address any issues by adjusting watering, fertilization, or implementing appropriate treatments based on the specific problem.</li></ul>
    
    By following these guidelines, you can help maintain the health of potato leaves and promote optimal growth and productivity in your potato plants.
    </p>
    </div>  
    },
    

    ];
    
    export function getTreatments() {
    return TREATMENTS;
    }
    
    export function getTreatment(id) {
    return TREATMENTS.find((treatment) => (treatment.id === id));
    }
    
    export function getTreatmentsByClass(dclass) {
    return TREATMENTS.filter((treatment) => (treatment.class === dclass));
    }