import React from 'react';
// import { Link } from 'react-router-dom';
import '../App.css';
// import { Button } from './Button';
import './About.cs';
// import './Cards.css';
// import CardItem from './CardItem';
// import About_CardItems from './About_CardItems'

function About() {
  return (
    <div className='abt__cards'>
      <h1>Coming Soon!</h1>
      {/* <p>Build Your Future with us now!</p> */}
      <div className='abt__cards__container'>
        <div className='abt__cards__wrapper'>
          <ul className='abt__cards__items'>        
            
          </ul>
        </div>
      </div>
    </div>
  );
}

export default About;
