import React from 'react';
import '../../App.css';
import ImageUpload from '../ImageUpload';
import About from '../About';
// import Footer from '../Footer';

function Services() {
  return (
    <>
  <About />
  </>
  )
  
}
export default Services;

