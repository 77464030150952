// import React from 'react';
// import '../../App.css';
// import CardItem from '../CardItem';

// export default function Products() {
//   return(
    
//   <h1 className='products'>PAGE'S UNDER CONSTRUCTION!</h1>);
// }

import React from 'react';
// import Navbar from '../Navbar.js'
import '../../App.css';
// import ImageUpload from '../ImageUpload';
import Footer from '../Footer';
import Navbar from '../Navbar';
// import CardItem from '../CardItem';
import Cards from '../Cards';


function Products() {
  return (
    <>
    
    <Navbar />
    <h1>This Page Is Under Construction!!</h1>
  {/* <CardItem /> */}
  <Cards />
  <Footer />
  
  </>
  )
  
}
export default Products;


