import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import Footer from './components/Footer';
import Therapydetails from './components/Therapydetails';




/**Import Login Components */
// import Username from './components/Username.js';
// import Password from './components/Password.js';
// import Register from './components/Register.js';
// import Profile from './components/Profile.js';
// import Recovery from './components/Recovery.js';
// import PageNotFound from './components/PageNotFound.js';
// import Reset from './components/Reset.js';


function App() {
  

  return (
    
    <>
    
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          {/* <Route path='/about' component={About} /> */}
          <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/Therapydetails' component={Therapydetails} />
          {/* <Route path='/login' component={Username} /> */}
          {/* <Route path='/register' component={Register} /> */}
          {/* <Route path='/profile' component={Profile} /> */}
          {/* <Route path='/recovery' component={Recovery} /> */}
          {/* <Route path='/reset' component={Reset} /> */}
          {/* <Route path='/password' component={Password} /> */}
          {/* <Route path='*' component={PageNotFound} /> */}
          
        </Switch>
        
        <Footer />
      </Router>
    </>
  );
}

export default App;

